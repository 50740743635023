import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ArrowRight from "../components/icons/arrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StoryChartingACourse = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav header="nomenu" urlParam={url_param}/>
      <div className="no-masthead relative"></div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
          <br /><br />
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">Charting a Course for Luxury and Innovation: Mr. Jean-Philippe Parain Headlines the Unveiling of the BMW Eurokars Experience Centre at the Eurokars Centre Launch</h2>
            <p className="mb-3 font-light"><strong>Singapore</strong></p>
            <p className="mb-5 lg:pr-5">
            BMW Eurokars Auto proudly hosted Mr. Jean-Philippe Parain, BMW Group Senior Vice President of Sales Regions Asia-Pacific, Eastern Europe, Middle East, and Africa, at the grand launch of the Eurokars Centre on 15 December 2023.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story1
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>
            
            <p className="mb-5 lg:pr-5">
            The debut of the Eurokars Centre, an impressive eight-story flagship building with a significant S$106 million investment, marked a pivotal moment. However, the highlight was the introduction of Singapore's pioneering Retail.Next concept housed in the BMW Eurokars Experience Centre, which signals a progressive leap in automotive retail. This milestone not only showcases a state-of-the-art facility, but it also positions Eurokars at the forefront of the industry with a visionary approach to elevating customer experiences.
            </p>
            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story2
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>
            <p className="mb-5 lg:pr-5">
            Spanning an expansive 796 square metres, the BMW Eurokars Experience Centre boasts a sleek and modern interior design. The space is thoughtfully designed to provide customers with an immersive experience, and curated with multi-functional areas, shared spaces, and open layouts, all meticulously designed to prioritise customer comfort and experience.
            </p>
            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story3
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
                <strong>Mr. Karsono Kwee (Centre), Executive Chairman of Eurokars Group pictured with Mr. Parain (left), and Mr. Lars Nielsen.</strong>
            </p>
            <p className="mb-5 lg:pr-5">
            The evening kicked off with an inspiring address from Mr. Karsono Kwee, the Executive Chairman of Eurokars Group. Following that, Mr. Parain, shared his enthusiasm for the launch, delivering an impactful message before presenting a token from the BMW Group to Mr. Kwee.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story4
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
                <strong>Mr. Eric Chua (third from left), Senior Parliamentary Secretary of Tanjong Pagar GRC with Mr. Parain (extreme right) on the Eurokars Centre opening night.</strong>
            </p>
            
            
            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story5
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
                
            </p>

                        
            <p className="mb-5 lg:pr-5">
            Mr. Eric Chua, Senior Parliamentary Secretary, Ministry of Culture, Community and Youth & Ministry of Social and Family Development, Adviser to Tanjong Pagar GRC Grassroots Organisations (Queenstown), lauded Mr. Kwee's automotive empire during his opening speech. Underlining Eurokars Group’s steadfast dedication to community service, Mr Chua emphasised their ongoing philanthropic efforts. A notable instance includes the donation of an ambulance to Tan Tock Seng Hospital and National Centre for Infectious Diseases (TTSH-NCID) during the COVID-19 pandemic, showcasing the Group's unwavering support during challenging times and dedication to impactful contributions beyond the automotive industry.
            </p>
            

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story6
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story7
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <p className="mb-5 lg:pr-5">
            The evening's festivities reached new heights with a dazzling drone and fireworks show, a spectacular first for an opening launch along the iconic Leng Kee Road.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story8
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>


            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story9
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story10
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            
            <p className="mb-5 lg:pr-5">
            As the night unfolded, attendees were treated to the thrilling car reveal of the BMW i7 M70, accompanied by live music from the band 'Gravity.' This dynamic combination created an unforgettable atmosphere that resonated throughout the entire evening, leaving a lasting impression on all who were present.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.story11
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <p className="mb-5 lg:pr-5">
            We extend our sincere gratitude to Mr. Jean-Philippe Parain, alongside our valued customers, for gracing us with their presence at this momentous event. Our BMW Eurokars Experience Centre is more than just a showroom, it stands as a symbol of our innovation, luxury, and our unwavering commitment to enriching the automotive experience for all enthusiasts.
            </p>

            <p className="mb-5 lg:pr-5">
            We eagerly anticipate the pleasure of hosting each and every one of you at our new space.
            </p>

            <div className="showroom-contact mt-8 md:mt-12">
 
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Experience Centre</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  Level 2, Eurokars Centre,<br />11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
                
              </div>
            </div>
          </div>

            <br /><br /><br />
            <p className="text-neutral-400 hover:text-black flex float-right">
              <span className="w-5 -ml-1.5 ">
                <ArrowRight />
              </span>
              <span className="ml-1 font-bold text-black md:transition-colors md:duration-150 md:text-neutral-400 md:hover:text-black">
              <Link
                  to={"/stories/" + url_param}
                  data-gtm-category="BMW Press Release"
                  data-gtm-action="Clicked_Link"
                  data-gtm-label="View Stories">
                  <strong>Back to Stories</strong>
                </Link>
              </span>
            </p>
              

            

            
            
          </div>
          
          
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "story_charting_a_course" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }

            story1 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            story2 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            story3 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            story4 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            story5 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            story6 {
                childImageSharp {
                    gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 300 }
                    placeholder: BLURRED
                    breakpoints: [1080, 1366, 1920]
                    outputPixelDensities: [1, 1.5, 2]
                    )
                }
            }
            story7 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 300 }
                    placeholder: BLURRED
                    breakpoints: [1080, 1366, 1920]
                    outputPixelDensities: [1, 1.5, 2]
                  )
                }
            }
            story8 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 300 }
                    placeholder: BLURRED
                    breakpoints: [1080, 1366, 1920]
                    outputPixelDensities: [1, 1.5, 2]
                  )
                }
            }
            story9 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 300 }
                    placeholder: BLURRED
                    breakpoints: [1080, 1366, 1920]
                    outputPixelDensities: [1, 1.5, 2]
                  )
                }
            }
            story10 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 300 }
                    placeholder: BLURRED
                    breakpoints: [1080, 1366, 1920]
                    outputPixelDensities: [1, 1.5, 2]
                  )
                }
            }
            story11 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    blurredOptions: { width: 300 }
                    placeholder: BLURRED
                    breakpoints: [1080, 1366, 1920]
                    outputPixelDensities: [1, 1.5, 2]
                  )
                }
            }
          }
        }
      }
    }
  }
`

export default StoryChartingACourse

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Story - Charting A Course for Luxury and Innovation",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
